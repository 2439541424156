import { Badge } from "./badge";

export const Cursos = (props) => {
  return (
    <div id='cursos' className='text-center'>
      <div className='container'>
        <div id='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 team'>
                    <div className='thumbnail'>
                      {' '}
                      <a
                        href={d.job} 
                      >
                        <Badge desc={d.badge}></Badge>
                        <img src={d.img} alt={d.alt} className='  -img opacity'/>
                        <div className='caption'>
                          <h3>{d.name}</h3>
                        </div>
                      </a>
                    </div>          
                  </div>
                ))
              : 'loading'}
        </div>
      </div>
    </div>
  )
}
