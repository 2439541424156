import { Badge } from "./badge";

export const CursosNew = (props) => {
  return (
    <div id='cursosNew' className='text-center'>
      <div className='container'>
        <div className='col section-title'>
          <h2>Cursos</h2>
          <p>
          Con ustedes la propuesta de cursos. Podés tomarlos completos o por clases, dependiendo del formato de cursada que tengan. Las fechas y los horarios se van renovando, así que quedate cerca! 
            <br />Ah! Y no te olvides que si te perdés una clase, te puedo enviar la grabación.
          </p>
        </div>
        <div className="d-flex justify-content-center">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 team'>
                    <div className='thumbnail'>
                      {' '}
                      <a
                        href={d.job} 
                      >
                        <Badge desc={d.badge}></Badge>
                        <img src={d.img} alt={d.alt} className='  -img opacity'/>
                        <div className='caption'>
                          <h3>{d.name}</h3>
                        </div>
                      </a>
                    </div>          
                  </div>
                ))
              : 'loading'}
        </div>
      </div>
    </div>
  )
}
